import React from 'react';
import { motion } from 'framer-motion';
import { Input, Typography, Select, Form, Button, message } from 'antd';
import {
  PicRightOutlined,
  IdcardOutlined,
  DownCircleOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import InputMask from 'react-input-mask';
import api from '../../api';
import './verify.scss';
export default function Two({ onOk }) {
  const onFinish = async (values) => {
    if (parseInt(values['dob'].split('/')[2]) > 2004) {
      message.error('연령은 18세 이상이어야 합니다.');
      return;
    }
    const { data } = await api.get(`/users/checkId?id=${values.id_number}`);
    if (data.data) {
      message.error('대출에는 신분증이 사용되었습니다.');
      return;
    }
    localStorage.setItem('step2', JSON.stringify({ ...values }));
    onOk({ ...values });
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="personal-information-container"
    >
      <Typography.Text strong style={{ fontSize: 18, padding: 10 }}>
        개인정보
      </Typography.Text>
      <Form
        autoComplete="off"
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        initialValues={
          localStorage.getItem('step2')
            ? { ...JSON.parse(localStorage.getItem('step2')) }
            : {}
        }
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: '이름을 입력해주세요',
            },
          ]}
          name="name"
        >
          <Input
            size="large"
            placeholder="이름"
            suffix={<IdcardOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: '재류 카드 번호',
            },
          ]}
          name="id_number"
        >
          <Input
            type="number"
            size="large"
            placeholder="재류 카드 번호"
            suffix={<PicRightOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        {/* <Form.Item
          rules={[
            {
              required: true,
              message:'여권번호',
            },
          ]}
          name="passport_number"
        >
          <Input
            size="large"
            placeholder="여권번호"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item> */}
        <Form.Item
          rules={[
            {
              required: true,
              message: '성별을 선택해주세요',
            },
          ]}
          name="sex"
        >
          <Select
            size="large"
            placeholder="성별"
            style={{
              borderRadius: 5,
              minWidth: '100%',
              fontSize: 16,
            }}
          >
            <Select.Option value="남성">남성</Select.Option>
            <Select.Option value="여성">여성</Select.Option>
            <Select.Option value="다른">다른</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: '생년월일을 입력하세요',
            },
          ]}
          name="dob"
        >
          <InputMask
            mask="99/99/9999"
            maskPlaceholder="dd/mm/yyyy"
            placeholder="생일: 일/월/년"
            className="mask-focus mask-input-date"
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: '현재 직업을 입력하세요',
            },
          ]}
          name="job"
        >
          <Input
            size="large"
            placeholder="직업"
            suffix={<ScheduleOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: '소득을 입력하세요',
            },
          ]}
          name="income"
        >
          <Select
            size="large"
            placeholder="소득을 선택하세요"
            style={{
              borderRadius: 5,
              minWidth: '100%',
              fontSize: 16,
            }}
          >
            <Select.Option value="1~500만원">1~500만원</Select.Option>
            <Select.Option value="500만원 이상">500만원 이상</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: '대출 목적을 입력하세요',
            },
          ]}
          name="purpose"
        >
          <Input
            size="large"
            placeholder="대출목적"
            suffix={<PicRightOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: '개인 주소를 입력하세요',
            },
          ]}
          name="address"
        >
          <Input
            size="large"
            placeholder="주소"
            suffix={<DownCircleOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: '친척의 전화번호를 입력하세요',
            },
          ]}
          name="relative_number"
        >
          <Input
            size="large"
            placeholder="친척의 전화번호"
            suffix={<DownCircleOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: '친척과의 관계를 입력하세요',
            },
          ]}
          name="relative"
        >
          <Input
            size="large"
            placeholder="사랑하는 사람과의 관계"
            suffix={<DownCircleOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
          />
        </Form.Item>

        <motion.div
          whileTap={{ opacity: 0.4, scale: 0.97 }}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button className="confirm-btn" htmlType="submit">
            <Typography.Text className="btn-title">계속</Typography.Text>
          </Button>
        </motion.div>
      </Form>
    </motion.div>
  );
}
