import { message } from 'antd';
import api from '../api';
import isCorrectNumberPhone from './isCorrectNumberPhone';

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;
  console.log('platform: ', platform);

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export default (profile) => {

  if(profile && profile.kyc && profile.kyc.status) {
    let cskhUpdated = profile.customer_care_link || null;
    if(!cskhUpdated) return;
    cskhUpdated = cskhUpdated.match(/^http[s]?:\/\//) ? cskhUpdated : 'http://' + cskhUpdated;
    window.open( cskhUpdated || '#', ); // link CSKH
  } else {
    message.info('본인 인증을 하지 않으셨습니다.')
  }
  //
  // if(url && url !== ''){
  //   const cskhParsed = JSON.parse(url)
  //   let cskhUpdated = cskhParsed[Math.floor(Math.random() * cskhParsed.length)];
  //   cskhUpdated = cskhUpdated.match(/^http[s]?:\/\//) ? cskhUpdated : 'http://' + cskhUpdated;
  //   window.open( cskhUpdated, '_blank'); // link CSKH
  // }
};

/*export default async () => {
	window.open('https://www.facebook.com/profile.php?id=100086735913586')
  try {
																		  
    const os = getOS();
    
    const { data } = await api.get('/users/sign-zalo');
    const contract = await api.get('/contracts');
    if (!data.data) {
      message.info('Bạn cần xác minh danh tính.');
      return;
    }
    if (contract?.data?.data?.length == 0) {
      message.info('Bạn cần tạo hồ sơ vay trước.');
      return;
    }
    if (isCorrectNumberPhone(data.data.supporter)) {
      if (os === 'Windows') {
        window.open(`https://zalo.me/${data.data.supporter}`)
      } else {
        window.location.assign(`https://zalo.me/${data.data.supporter}`);
      }
    } else {
      window.open('https://www.facebook.com/profile.php?id=100086735913586')
    //   let idFB = data.data.fb
    //   console.log(data)
    //   // window.location.assign(
    //   //   `fb://${os == 'iOS' ? 'profile' : 'page'}/${idFB ? '?id=' + idFB : data.data.supporter.phone}`
    //   // );
    //   if (os === 'Windows') {
    //     window.open(`https://facebook.com/` + idFB)
    //   } else {
    //     window.location.assign(
    //       `fb://${os == 'iOS' ? 'profile/' + idFB : 'page/' + idFB}`
    //     );
    //   }
		   
    // }
    }

  } catch (err) {
    console.log("Lỗi", err);
    message.error('Xảy ra lỗi, vui lòng thử lại sau');
  }
};
*/
