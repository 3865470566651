import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
import koKR from 'antd/es/locale/ko_KR';

ReactDOM.render(
  <ConfigProvider locale={koKR}>
    <App />
  </ConfigProvider>,
  document.getElementById('app')
);
