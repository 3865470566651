import { useState } from 'react';
import './Login.scss';
import { Image, Typography, Input, Button, message, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import isCorrectNumberPhone from '../../utils/isCorrectNumberPhone';
import api from '../../api';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../../redux/constants';
import { motion } from 'framer-motion';
// import logo from '../../assets/logo-khong-nen.png';
import logo from '../../assets/hanabank/Hana_Bank_Logo_(eng).png';
import _buildings from '../../assets/buildings.jpg';
import DieuKhoan from '../../components/DieuKhoan';

import './Login.scss';
export default () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    phone: '',
    password: '',
    repassword: '',
    check: false,
  });
  // const toggleCheck = (e) => setState({ check: e.target.checked });
  const [loading, setLoading] = useState(false);
  async function confirmSignup() {
    try {
      if (!state.phone || !state.password) {
        message.error('정보를 비워 둘 수 없습니다.');
        return;
      }
      if (!isCorrectNumberPhone(state.phone)) {
        message.error('전화번호 형식이 올바르지 않습니다.');
        return;
      }
      if (state.repassword !== state.password) {
        message.error(
          '비밀번호가 일치하지 않습니다. 비밀번호를 다시 확인해주세요'
        );
        return;
      }
      if (state.password.length < 6 || state.password.length > 20) {
        message.error('비밀번호는 6자에서 20자 사이여야 합니다.');
        return;
      }
      if (!state.check) {
        message.error('등록하려면 약관 및 서비스를 동의해야 합니다');
        return;
      }

      setLoading(true);
      const { data } = await api.post('/auth/signup', {
        phone: state.phone,
        password: state.password,
        repassword: state.repassword,
        check: true,
      });

      if (data.code === 405) {
        message.error(data.message)
        history.push('/auth?phone=' + state.phone)
        return
      }

      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('role', data.role);
      dispatch({ type: LOGIN_SUCCESS, payload: {} });
      message.success('계정 생성에 성공했습니다.');
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: ` linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%), url('${_buildings}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <motion.div
        className="empty-div"
        initial={{
          opacity: 0.4,
          scale: 0.35,
        }}
        transition={{ duration: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
      >
        <Image
          src={logo}
          width={'30%'}
          style={{
            marginBottom: '50px',
            marginTop: '50px',
            borderRadius: '10px'
          }}
          preview={false}
        />
      </motion.div>
      <div className="form">
        <div className="form-header">
          <Typography.Text className="title">계정 등록</Typography.Text>
        </div>
        <div className="form-body">
          <Input
            className="input"
            autoComplete="off"
            value={state.phone}
            onChange={(e) => setState({ ...state, phone: e.target.value })}
            size="large"
            placeholder="전화번호를 입력하세요"
          />

          <Input
            className="input"
            autoComplete="off"
            value={state.password}
            type="password"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            size="large"
            placeholder="비밀번호"
          />

          <Input
            className="input"
            autoComplete="off"
            size="large"
            type="password"
            placeholder="비밀번호를 다시 입력하세요"
            value={state.repassword}
            onChange={(e) => setState({ ...state, repassword: e.target.value })}
          />

          <div className="check-box">
            <Checkbox
              defaultChecked={false}
              onChange={(e) => {
                setState({ ...state, check: e.target.checked });
              }}
            >
              에 동의합니다
            </Checkbox>{''}
            <DieuKhoan />
          </div>

          <Button
            className="login-btn"
            onClick={confirmSignup}
            loading={loading}
            size="large"
          >
            <Typography.Text style={{ color: '#fff' }} strong>
              {' '}
              가입
            </Typography.Text>
          </Button>
          <div style={{}}>
            <br />
            <Typography.Text
              style={{
                padding: '3px 0px',
                color: '#777',
                fontWeight: '400',
                paddingHorizontal: 20,
              }}
            >
              비밀번호는 6자에서 20자 사이여야 합니다.
            </Typography.Text>
            <br />
            <Typography.Text
              style={{
                padding: '3px 0px',
                color: '#777',
                fontWeight: '500',
              }}
            >
              예시:
            </Typography.Text>
            <br />
            <Typography.Text
              style={{
                padding: '3px 0px',
                color: '#777',
                fontWeight: '400',
                paddingHorizontal: 20,
              }}
            >
              비밀번호: 123456
            </Typography.Text>
          </div>

          <div className="form-footer">
            <Typography.Link
              onClick={() => history.push('/auth')}
              style={{ fontSize: 15, color: '#333' }}
            >
              이미 계정이 있으신가요? 지금 로그인하세요
            </Typography.Link>
          </div>
        </div>
      </div>
    </div>
  );
};
