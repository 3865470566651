import { Button, Typography } from 'antd';
import { useState } from 'react';
import ModalHopDong from './ModalHopDong';

export default function App({ data, profile }) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        shape="round"
        style={{ background: '#ff7323', margin: 10 }}
        onClick={() => setVisible(true)}
      >
        <Typography.Text style={{ color: '#fff' }} strong>
          계약 보기
        </Typography.Text>
      </Button>
      <ModalHopDong visible={visible}
                    setVisible={setVisible}
                    data={data}
                    profile={profile}/>
    </>
  );
}
