import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';

export default function App({ data, profile }) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Typography.Link onClick={() => setVisible(true)}>
        서비스 약관
      </Typography.Link>
      <Modal
        visible={visible}
        onOk={() => setVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable
        onCancel={() => setVisible(false)}
      >
        <center>
          <Typography.Text strong style={{ fontSize: 20 }}>
            일반 규정
          </Typography.Text>
        </center>
        <Typography.Paragraph>
          대출자는 19세에서 60세 사이여야 하며, 본인 명의의 은행 계좌가 있거나 가족의 계좌를 빌릴 수 있어야 하고, 
          주민등록증 또는 신분증을 소지해야 하며, 본인 명의의 전화번호, 페이스북 또는 자로 계정을 제공하고 최소한 
          한 명의 가족 연락처를 제공해야 합니다
        </Typography.Paragraph>
        <Typography.Paragraph>
          Long Bình 은 첫 대출 신청을 30분에서 1시간 이내에 심사합니다. 
          대출이 승인된 후 다음 대출 신청은 심사 시간이 5분으로 단축됩니다.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Long Bình 은 제공하신 정보를 심사합니다. 
          정보를 많이 제공할수록 대출이 더 용이해집니다. 
          또한, 신용도가 상승하며 대출 금액이 더 커질 수 있고, 
          수수료와 이자율이 낮아지며 대출 기간도 더 길어질 수 있습니다.
        </Typography.Paragraph>
        <Typography.Paragraph>
          부정확하거나 불분명한 정보는 대출 신청이 거부될 수 있습니다
        </Typography.Paragraph>
        <Typography.Paragraph>
          신한은행 고객 지원팀에 연락하여 도움을 받으세요!
        </Typography.Paragraph>
      </Modal>
    </>
  );
}
