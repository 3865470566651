import React, { useEffect } from 'react';
import { Modal, Typography } from 'antd';
import moment from 'moment/moment';
import conDau1 from '../assets/dau-do2.jpg';

export default function ModalHopDong({visible, setVisible, data, profile}) {

  return (
    <Modal
      visible={visible}
      onOk={() => setVisible(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable
      onCancel={() => setVisible(false)}
      style={{overflow: 'hidden', top: 15}}
    >
      <Typography.Text strong>
        <center>대출 계약서</center>
      </Typography.Text>
      <br />
      <p>
        대출기관:
        <Typography.Text strong>
          {' '}
          KEB Hana Bank
        </Typography.Text>
      </p>
      <p>
        대출인:
        <Typography.Text strong>
          {' '}
          {profile?.kyc?.name || '완료되면 업데이트됨'}
        </Typography.Text>
      </p>
      <p>
        {' '}
        주민등록번호:
        <Typography.Text strong>
          {' '}
          {profile?.kyc?.id_number || '완료되면 업데이트됨'}
        </Typography.Text>
      </p>
      <p>
        서명 날짜:
        <Typography.Text strong>
          {' '}
          {moment(data?.created_at).format('hh:mm A DD/MM/YYYY')}
        </Typography.Text>
      </p>
      <p>
        대출금액:{' '}
        <Typography.Text strong>
          {data?.amount.toLocaleString()}원
        </Typography.Text>
      </p>
      <p>
        계약 코드:{' '}
        <Typography.Text strong>
          {data?.slug || '완료되면 업데이트됨'}
        </Typography.Text>
      </p>
      <p>
        대출기간:{' '}
        <Typography.Text strong>{data?.times}개월</Typography.Text>
      </p>
      <p>
        대출이자율: 월 <Typography.Text strong>0.9%</Typography.Text>
      </p>
      <p>
        계약서에는 당사자들이 협상 후 대출 계약에 도달했다고 명시되어 있습니다.
        품질과 평등, 자발성 및 합의를 기반으로 합니다. 모든 당사자
        귀하는 서명 후 본 계약의 모든 조항을 주의 깊게 읽어야 합니다.
        본 계약은 당사자들이 완전히 이해하고 동의한 것으로 간주됩니다.
        본 계약의 모든 조건과 내용.
      </p>
      <p>
        1. 평등, 자발적, 정직, 명예의 원칙에 따라
        신용, 양측은 협상 후 대출 계약을 체결하기로 합의하고
        함께 구현을 약속합니다.
      </p>
      <p>
        2. 차입자가 대출계약서 첨부서류를 제출하고 그 효력이 발생합니다.
        이 대출 계약만큼 합법적입니다.
      </p>
      <p>
        3. 차용자는 대출자가 제공하는 애플리케이션 지갑의 대출 금액을 기준으로 원리금을 계산하는 주문을 생성합니다.
      </p>
      <p>4. 보증 조건.</p>
      <p>
        - 차용인은 대출금을 불법행위에 사용해서는 안 됩니다.
        그렇지 않으면 대출 기관은 차용인에게 즉시 돈을 상환하도록 요청할 권리가 있습니다.
        원금과 이자로 인해 발생하는 모든 법적 책임은 차용인이 부담해야 합니다.
      </p>
      <p>
        - 차용인은 계약서에서 정한 기한 내에 원리금을 상환하여야 합니다. 연체된 부분에 대해 대출기관은 기한 내에 대출금을 회수하고 당일 총 대출 금액의 %(연체이자)를 징수할 권리가 있습니다.
      </p>
      <p>
        - 각 채무상환의 원금과 이자는 시스템에 의해 차용인이 보유하고 있는 은행계좌에서 대출기관의 은행계좌로 자동 이체됩니다. 차용인은 매월 상환일 이전에 은행 계좌에 충분한 돈이 있는지 확인해야 합니다.
      </p>
      <p>5. 계약 위반에 대한 책임.</p>
      <p>
        - 차용인이 약정에 따라 대출금을 상환하지 않는 경우. 차용인은 소송 결과로 발생한 청산 손해 배상금, 변호사 비용, 소송 비용, 여비 및 기타 비용을 부담해야 합니다.
      </p>
      <p>
        - 대출기관은 차용인이 대출에 영향을 미치는 상황을 갖고 있거나 그럴 가능성이 있다고 판단하는 경우, 대출 기한 이전에 차용인에게 대출금을 즉시 상환하도록 요청할 권리가 있습니다.
      </p>
      <p>
        - 차용인과 보증인은 어떠한 사유로도 계약조건을 위반해서는 안 됩니다.
      </p>
      <p>6. 계약분쟁의 해결방법.<br />
       본 계약 이행 중 발생하는 분쟁은 당사자 간의 우호적인 협상을 통해 해결되거나 제3자가 중재자로 활용될 수 있습니다. 협상이나 조정이 실패할 경우 대출 기관의 본점이 있는 인민 법원에 분쟁이 제기될 수 있습니다. .
      </p>
      <p>
        7. 차입자가 대출 승인 절차에 실패한 경우
        잘못된 신분증, 잘못된 은행 카드 등 다양한 요인으로 인해
        잘못된 디렉토리. 이러한 허위 정보는 시스템에 의해 감지됩니다.
        사기나 대출 위조가 의심되는 경우, 대출자는 이를 처리하기 위해 대출 기관과 적극적으로 협력해야 합니다.
      </p>
      <p>
        8. 협조하지 않는 경우 대출 기관은 한국 법원에 소송을 제기할 권리가 있으며, 불량 채무 기록은 신용 보고서에 반영되어 차용인의 향후 신용, 은행 대출에 영향을 미치고 차용인의 자녀인 친척의 소비를 제한합니다.
      </p>
      <br />

      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px', gap: 10 }}>
        <div>
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>대출인</p>
          {data?.signature_capture && <img src={`${data?.signature_capture}`} width="120px" />}
          <h5 style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 3 }} >{profile?.kyc?.name}</h5>
        </div>
        <div>
          <p style={{ textAlign: 'center', fontWeight: 'bold', position: 'relative' }}>대출기관</p>
          <img src={conDau1} width="150px" style={{ marginRight: '-35px' }} />
        </div>
      </div>
    </Modal>
  );
}
